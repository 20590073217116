<template>
  <AuthLayoutContent>
    <template v-slot:left>
      <div class="step d-flex align-items-center mx-auto">
        <div>
          <p class="text-danger fw-bold text-capitalize" v-if="getErrors.payment">
            {{ getErrors.payment }}
          </p>

          <h1>
            {{ $t("How may we address you?") }}
          </h1>
          <p class="fw-medium text-gray pb-4">
            {{ $t("Share you contact details below") }}
          </p>
          <Spinner v-if="!mounted"></Spinner>
          <div v-else>
            <TextInput
              name="first_name"
              :label="$t('First Name')"
              :placeholder="$t('First Name')"
              v-model="form.first_name"
              rules="required"
            />

            <TextInput
              name="last_name"
              :label="$t('Last Name')"
              :placeholder="$t('Last Name')"
              v-model="form.last_name"
              rules="required"
            />

            <TextInput
              name="date_of_birth"
              :label="$t('Date of birth')"
              :placeholder="$t('Date of birth')"
              v-model="form.dob"
              rules="required"
              :masked="true"
              :mask="[{ mask: '00-00-0000', lazy: false }]"
            />

            <div v-if="form.country == 'NL'">
              <div class="row">
                <div class="col-md-4">
                  <TextInput
                    name="zipcode"
                    :label="$t('Zipcode')"
                    :placeholder="$t('Zipcode')"
                    v-model="form.zipcode"
                    rules="required"
                    class="p-0"
                  />
                </div>
                <div class="col-md-4">
                  <TextInput
                    name="houseNr"
                    :label="$t('House number')"
                    :placeholder="$t('House number')"
                    v-model="form.houseNr"
                    rules="required"
                  />
                </div>
                <div class="col-md-4">
                  <TextInput
                    name="houseAd"
                    :label="$t('House addition')"
                    :placeholder="$t('House addition')"
                    v-model="form.houseAd"
                  />
                </div>
              </div>

              <TextInput
                name="city"
                :label="$t('City')"
                :placeholder="$t('City')"
                v-model="form.city"
              />

              <TextInput
                name="address"
                :label="$t('Address')"
                :placeholder="$t('Address')"
                v-model="form.address"
              />
            </div>
          </div>

          <div class="mt-3">
            <small class="text-danger" v-if="getErrors.tenant">{{
              getErrors.tenant
            }}</small>
            <small class="text-danger" v-if="getErrors.address">{{
              getErrors.address
            }}</small>
          </div>

          <button
            class="btn btn-primary btn-block mt-4 mb-5"
            :disabled="!shouldSubmit"
            @click="submit"
          >
            {{ $t("Next step") }}
            <b-spinner
              v-if="getLoadings.tenant"
              small
              label="Spinning"
              variant="white"
            ></b-spinner>
          </button>
        </div>
      </div>
    </template>
    <template v-slot:right>
      <div class="step__rightSection mx-auto">
        <RightPoints active="3" />
      </div>
    </template>
  </AuthLayoutContent>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AuthLayoutContent from "@/components/common/AuthLayoutContent.vue";
import RightPoints from "./RightPoints.vue";
import TextInput from "../../../components/ui/form/controls/TextInput.vue";
import Spinner from "../../../components/ui/Spinner.vue";
const moment = require("moment");

export default {
  data() {
    return {
      countries: [],
      institutes: [],
      instituteLoading: false,
      submitting: false,
      form: {
        first_name: "",
        last_name: "",
        dob: "",
        zipcode: "",
        houseNr: "",
        houseAd: "",
        address: null,
        address_value: "",
      },
      submitted: true,
      error: "",
      mounted: false,
      loadingAddress: false,
    };
  },
  components: {
    AuthLayoutContent,
    RightPoints,
    TextInput,
    Spinner,
  },
  computed: {
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
    ...mapGetters("auth", ["user"]),
    ...mapGetters("tenant", ["tenant"]),
    shouldSubmit() {
      let flag = this.form.first_name && this.form.last_name && this.form.dob;

      if (this.tenant && this.tenant.country == "NL") {
        flag = flag && this.form.zipcode && this.form.houseNr && this.form.address;
      }

      return flag && !this.getLoadings.tenant && !this.loadingAddress;
    },
  },
  async mounted() {
    let tenant_id = this.$route.params.tenant_id;
    if (tenant_id) {
      await this.getTenant(tenant_id);
    }

    let tenant = this.tenant;
    console.log("this.user", this.tenant);

    this.form.first_name = tenant.first_name;
    this.form.last_name = tenant.last_name;
    this.form.dob = moment(tenant.dob).format("MM-DD-YYYY");
    this.form.zipcode = tenant.zipcode;
    this.form.houseNr = tenant.houseNr;
    this.form.houseAd = tenant.houseAd;
    this.form.address = tenant.address;
    this.form.city    = tenant.city;
    this.form.country = tenant.country;
    this.mounted = true;
  },
  methods: {
    ...mapActions("tenant", ["updateTenant", "getTenant"]),
    customLabel({ name }) {
      return name;
    },
    async submit() {
      console.log("ff", this.form);

      if (!this.shouldSubmit) {
        return;
      }

      await this.updateTenant(this.form);
      this.$router.push({
        name: "ExpatStep5",
        params: { tenant_id: this.tenant.id },
      });
    },
    onPartialData(data) {
      console.log("data", data);
    },
    async inputChange() {
      // if (this.form.zipcode && this.form.houseNr) {
      //   this.form.address_value = "Loading....";
      //   this.loadingAddress = true;
      //   try {
      //     let address = await this.getAddress({
      //       zipcode: this.form.zipcode,
      //       house_number: this.form.houseNr,
      //       house_ad: this.form.houseAd,
      //     });
      //     this.form.address_value = `${address.street} ${this.form.houseNr} ${
      //       this.form.houseAd ?? ""
      //     } ${address.city}`;
      //     this.form.address = address;
      //   } catch (error) {
      //     this.form.address_value = "";
      //   }
      //   this.loadingAddress = false;
      // }
    },
  },
};
</script>

<style lang="scss">
body {
  @include md {
    background: white;
  }
}

.step {
  max-width: 412px;

  @include md {
    padding-top: 62px;
  }

  @include xs {
    max-width: 90%;
  }

  &__heading {
    @include font(1.375rem, $primary, 700);
  }

  &__rightSection {
    width: 362px;

    @include xs {
      width: 93%;
    }
  }
}
</style>
